import React from "react"
import _ from "lodash"

const CategoryMember = ({memberList, style}) => {
  const labelStyle = style ? style : {
    padding: "4px",
    textAlign: "center",
    fontSize: "12px",
    letterSpacing: "1px",
  }

  // PREMIUM → プレミアム会員専用
  // PREMIUM, MONTHLY → 有料会員専用
  // PREMIUM, MONTHLY, FREE → 一般販売
  // 現状は低い会員で表示が決定する
  if (_.includes(memberList, "FREE")) {
    return (
      <div className="label free" style={{background: "var(--label-free)", ...labelStyle}}>
        <span style={{color: "var(--label-free-text)"}}>一般販売</span>
      </div>
    )
  } else if (_.includes(memberList, "MONTHLY")) {
    return (
      <div className="label monthly" style={{background: "var(--label-monthly)", ...labelStyle}}>
        <span style={{color: "var(--label-monthly-text)"}}>有料会員専用</span>
      </div>
    )
  } else if (_.includes(memberList, "PREMIUM")) {
    return (
      <div className="label premium" style={{background: "var(--label-premium)", ...labelStyle}}>
        <span style={{color: "var(--label-premium-text)"}}>プレミアム会員専用</span>
      </div>
    )
  } else {
    return (
      <div className="label out" style={{background: "var(--label-out)", ...labelStyle}}>
        <span style={{color: "var(--label-out-text)"}}>販売していません</span>
      </div>
    )
  }
}

export {CategoryMember}
